<template>
    <NuxtLink
        :to="localePath('/' + Url)"
        class="text-sm font-semibold leading-6 text-gray-900">
        {{ Titel }}
    </NuxtLink>
</template>

<script setup>
const props = defineProps({
    component: {
        type: Object,
        required: true
    }
})

const Titel = computed(() => {
    return props.component.Seite.data?.attributes.Titel ?? ''
})

const Url = computed(() => {
    return props.component.Seite.data?.attributes.url ?? ''
})
</script>
